import React from 'react';
import './artwork.css';
import ArtLoop from '../../assets/rfartloop.gif';
import ArtBg from '../../assets/art_bg.svg';
import ModalVideo from 'react-modal-video'
import { useState } from "react";
import Fancybox from '../../components/fancybox';


const Artwork = () => {
    // const [isOpen, setOpen] = useState(false)


    return (
        <>





            <div id="artwork" className="rf__container-artwork section__padding">
                <div className="rf__container-artwork_row">
                    <div data-aos="fade-up" className="rf__container-artwork_column column__padding">
                        <h1>THE ROBOTS</h1>
                        <p>Robot Feelings is a 10101 generative NFT collection living on the Ethereum blockchain. Each Robot is unique and generated from over 300 traits and 8 feelings.</p>
                        <br></br>
                 

                        <p>Follow us on Twitter  <a style={{fontWeight: "bold"}} href="https://twitter.com/robotfeels" target="_blank">@robotfeels</a> to get all the details as we build outloud.</p>
                        <br></br>
                        <p>Join us on Discord</p>

                        <div style={{marginTop: 30}}>
                        <a className='rf__btn-cta' type='button' href="https://discord.gg/fqmEWKFGVD" target="_blank" rel="noreferrer">JOIN DISCORD HERE</a>
                        </div>
                        <div >

                        <Fancybox options={{ infinite: false }}>
  <p>
    <button
      data-fancybox="video"
      data-src="https://youtu.be/nl8zQnU30X4"
      className="rf__btn-cta trailer"
    >
     WATCH THE TEASER
    </button>
  </p>
</Fancybox>

                        
                        </div>
                        <div style={{marginTop: 30}}>
                        <a className='rf__btn-cta mint' type='button' href="https://www.alphashares.io/mint/robot-feelings" target="_blank" rel="noreferrer">MINT</a>
                        </div>
                        <br/>
                        <div className="rf__container-traits_row">
                            <div className="rf__container-traits_column">
                                <h2>Mint .04 eth </h2>
                                {/* <h2>000 Accessories</h2> */}
                            </div>
                            <div className="rf__container-traits_column">
                                <h2>12/16/22</h2>

                                {/* <h2>000 Accessories</h2> */}
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="rf__container-artwork_column">
                        <img src={ArtLoop}
                            alt="robot feelings image"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Artwork

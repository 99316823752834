import React, { Component } from "react";
import Slider from "react-slick";
import './slider.css';
import Brand1 from '../../assets/rf_brand1.png';
import Brand2 from '../../assets/rf_brand2.png';
import Brand3 from '../../assets/rf_brand3.png';
import Brand4 from '../../assets/rf_brand4.png';
import Brand5 from '../../assets/rf_brand5.png';
import Brand6 from '../../assets/rf_brand6.png';
import Brand7 from '../../assets/rf_brand7.png';
import Brand8 from '../../assets/rf_brand8.png';
import Brand9 from '../../assets/brand9.jpg';


export default class VariableWidth extends Component {
  render() {
    const settings = {
      className: "slider",
      dots: false,
      arrows: false,
      infinite: true,
      draggable: true,
    //   centerMode: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "linear"
    };
    return (
      <div className="sliderRow">
        <Slider {...settings}>
          <div>
          <img src={Brand1}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand2}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand3}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand4}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand5}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand6}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand7}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand8}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
          <div>
          <img src={Brand9}
                          alt="Robot Feelinbgs Branding and History"/>
          </div>
        </Slider>
      </div>
    );
  }
}
import React from "react";
import './accordionitem.css';
import { useRef } from "react";


const AccordionItem = ({faq, onToggle, active}) => {
    const {question, answer} = faq;
    const contentEl = useRef();
    return (
      <>
        <li className={`rf__accordion-list_item" ${active ? 'active' : ""}`}>
            <button className="rf__accordion-button" onClick={onToggle}>
                {question}
                <span className="rf__accordion-control">{active ? '-' : '+'}</span>
            </button>
            <div ref={contentEl} 
                className='rf__accordion-list_answer'
                style={
                active
                ? {height: contentEl.current.scrollHeight}
                : {height: '0px'}
            } >
                <div className="rf__accordion-list_answer-item">{answer}</div>
            </div>
        </li>
      </>
    );
  };
  
  export default AccordionItem

//   className={`rf__accordion-list_answer ${active ? 'open' : ''}`}
import React from 'react';
import './faq.css';
import {Accordion} from '../../components';

const Faq = () => {
    return (
        <>
            <div className='rf__accordion-container'>
                <div className="rf__container-faq_text">
                    <h1>THE FAQ</h1>
                </div>
                <Accordion/>
            </div>
        </>
    );
}

export default Faq

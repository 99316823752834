import React from 'react';
import './creator.css';
import Robot from '../../assets/outoforder.png';

const Creator = () => {
    return (
        <>
            <div className="rf__container-creator section__padding">
                <div className="rf__container-creator_row">
                    <div className="rf__container-creator_column column__padding">
                        <h1>THE CREATOR</h1>
                        <p>The Creator has been drawing The Robots for over 20 years.</p>
                        <p>He started drawing them out of boredom, but after a while the little robot characters turned into a way for him to express himself.</p><br></br>
                        <p>He got tired of people asking him “Why do you look mad all the time?” or “Why do you look so sad?” The only response he could think of was, “That’s just my face.”</p><br></br>
                        <p>He wasn’t exactly the most expressive person. So he chose to express himself through these illustrations. It turned out that he wasn’t the only one who saw himself in The Robots. Other people could relate to them too, and even offered to buy his art.</p><br></br>
                        <p>Fast forward to 2021. The Creator was still illustrating these robots but his friends started to bug him about turning them into these weird things called NFTs. His friends wanted to bring these amazing little Robots to life, they wanted to create a community of people like The Creator. People that were tired of being told to smile more or that they have RBF. Though The Creator is an introvert, he liked the idea of a community of people that loved The Robots as much as he does.</p><br></br>
                        <p>So he finally gave in, and the rest is history.</p>

                    </div>
                    <div className="rf__container-creator_column">
                        <img src={Robot}
                            alt="Out of order robot"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Creator

import React from 'react';
import './newsletter.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe'


const url = 'https://robotfeelings.us14.list-manage.com/subscribe/post?u=f0c098cfd509b89dd82f78e85&amp;id=981baf2f2a';
const SimpleForm = () => <MailchimpSubscribe url={url}/>


// const Newsletter = () => (


//   <MailchimpSubscribe
//     url={url}
//     render={({ subscribe, status, message }) => (
//       <div className='rf__newletter-container'>
//         <div className="rf__newletter-content__input">
//           <SimpleForm onSubmitted={formData => subscribe(formData)} />
//           {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
//           {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
//           {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
//         </div>
//       </div>
//     )}
//   />
// )

const Newsletter = () => {
    return (
      <>
      <div className='signup section__padding'>
      <div className='rf__newletter-container-copy'>
        <h1>SIGN UP FOR UPDATES</h1>
          <p>We write to our friends every other week. Sign up to learn more about Robot Feelings, get exclusive offers, and be the first to know about project updates.</p>
        </div>

        <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div className='rf__newletter-container'>
        <div className="rf__newletter-content__input">
          <SimpleForm onSubmitted={formData => subscribe(formData)} />
          {status === "sending" && <div className="message" style={{ color: "white" }}>sending...</div>}
          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
          {status === "success" && <div style={{ color: "white" }}>Subscribed !</div>}
        </div>
      </div>
    )}
    className='MailchimpStyle'
  />


        {/* <div className='rf__newletter-container'>
            <div className="rf__newletter-content__input">
                <input type="email" placeholder="EMAIL"/>
                <button type="button">Sign Up</button>
            </div>
        </div> */}
      </div>
      </>
    );
}

export default Newsletter

import React from 'react';
import './team.css';
import Gil from '../../assets/gil.jpg';
import Dan from '../../assets/dan.jpg';
import Seth from '../../assets/seth.png';
import Typhaney from '../../assets/typhaney.png';
import Denzel from '../../assets/denzel.png';
import Evan from '../../assets/evan.png';
import Oscar from '../../assets/oscar.png';
import Lihui from '../../assets/Lihui.jpg';
import Twitter from '../../assets/fa-twitter.svg';
import IG from '../../assets/Instagram.svg';
import LI from '../../assets/linkedin.svg';


const Team = () => {
    return (
        <>
            <div id="team" className='rf__container-team section__padding'>
                <div className="rf__container-team_text">
                    <h1>THE TEAM</h1>
                    <p>(100% true except for the parts that are made up) </p>
                </div>
                <div className="rf__team-row">
                   <div data-aos="fade-up" className="rf__card_team">
                        <img src={Dan} alt="Dan"/>
                        <h2>THE CREATOR</h2>
                            <p>Artist/Director of Animation</p><br></br>
                            <p>Shy boi who also used to rock a red mohawk (contradictory, we know). </p>
                            <br></br>
                            <div className='team_socials'>
                            <a href="https://twitter.com/Customersbot" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                          </div>
                    </div>
                    <div data-aos="fade-up" className="rf__card_team">
                        <img src={Gil} alt="Gil"/>
                        <h2>GIL</h2>
                        <p>Co-Founder</p><br></br>
                          <p>Traveled as a lead singer in a band. Sneakerhead apprentice and aspiring muay thai fighter. 
                          </p>
                          <br></br>
                          <div className='team_socials'>
                            <a href="https://twitter.com/thegilsandoval" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://instagram.com/thegilsandoval" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                            <a href="https://linkedin.com/in/gilsandoval" target="_blank" rel="noreferrer"><img src={LI} alt="Linkedin"/></a>
                          </div>
                    </div>
                    <div data-aos="fade-up" className="rf__card_team">
                        <img src={Seth} alt="Seth"/>
                        <h2>SETH</h2>
                        <p>Co-Founder</p><br></br>
                          <p>Serial entrepreneur, sneaker enthusiast and aspiring travel blogger. 
                          </p>
                          <br></br>
                          <br></br>
                          <div className='team_socials'>
                            <a href="https://twitter.com/sethwells" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://instagram.com/robotfeelings" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                            <a href="https://instagram.com/robotfeelings" target="_blank" rel="noreferrer"><img src={LI} alt="Linkedin"/></a>
                          </div>
                    </div>
                    <div data-aos="fade-up" className="rf__card_team">
                        <img src={Typhaney} alt="Typhaney"/>
                        <h2>TYPHANEY</h2>
                        <p>Director of Technology</p><br></br>
                          <p>Concert security turned jewelry maker turned developer, we think she’s a shapeshifter. 
                          </p>
                          <br></br>
                          <div className='team_socials'>
                            <a href="https://twitter.com/TyphaneyBdev" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://linkedin.com/in/typhaneybennett" target="_blank" rel="noreferrer"><img src={LI} alt="Linkedin"/></a>
                          </div>
                    </div>
                    {/* <div data-aos="fade-up" className="rf__card_team">
                        <img src={Denzel} alt="Denzel"/>
                        <h2>DENZElL</h2>
                        <p>Brand Strategist</p><br></br>
                          <p>Named after Denzel Washington. She looks innocent, but curses like a sailor.
                          </p>
                          <br></br>
                          
                          <div className='team_socials'>
                            <a href="https://twitter.com/denzgallegos" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://instagram.com/denzellpnw" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                          </div>
                    </div>   */}
                    <div data-aos="fade-up" className="rf__card_team">
                        <img src={Evan} alt="Evan"/>
                        <h2>EVAN</h2>
                        <p>Branding & Digital Design</p><br></br>
                          <p>Sauce boss, part time dog walker, and annoyingly good at everything. 
                          </p>
                          <br></br>
                          <br></br>
                          
                          <div className='team_socials'>
                            <a href="https://twitter.com/evanyuen" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://instagram.com/evan.yuen" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                          </div>
                    </div>
                    <div data-aos="fade-up" className="rf__card_team">
                        <img src={Oscar} alt="Oscar"/>
                        <h2>OSCAR</h2>  
                        <p>Animator</p><br></br>
                          <p>A living cartoon character who will make you a playlist (but only on a cassette). 
                          </p>
                          <br></br>
                         
                          <div className='team_socials'>
                            <a href="https://twitter.com/segapunk" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
                            <a href="https://www.instagram.com/segapunk/ " target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                          </div>
                    </div>
                    {/* <div data-aos="fade-up" className="rf__card_team">
                        <img src={Lihui} alt="Lihiu"/>
                        <h2>LIHUI</h2>
                        <p>Animator</p><br></br>
                          <p>Has an absolutely massive bear costume. And even made a TikTok for it. 
                          </p>
                         <br></br>
                         <br></br>
                        
                         <div className='team_socials'>
                            <a href="https://twitter.com/lihuichenn" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a>
                          </div>
                  </div> */}
                </div>
            </div>
        </>
    );
}

export default Team

import React from 'react';
import './accordion.css';
import { faq, faqs } from '../data'
import {AccordionItem} from '../../components';
import { useState } from 'react';

const Faq = () => {
    const [clicked, setClicked] = useState("0");    

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked('0');
        }
        setClicked(index);
    };

  return (
    <>
    <ul className='rf__accordion-list '>
        {faq.map((faq, index) => (
            <AccordionItem key={index} faq={faq}
            onToggle={() => handleToggle(index)}
            active={clicked === index}
            />
        ))}
    </ul>
    </>
  );
};

export default Faq
import React from 'react';
import './header.css';
import RobotOne from '../../assets/robot_1.png';
import RobotTwo from '../../assets/robot_2.png';
import RobotThree from '../../assets/robot_3.png';
import RobotFour from '../../assets/robot_4.png';
import RobotFive from '../../assets/robot_5.png';
import RobotSix from '../../assets/robot_6.png';
import RobotSeven from '../../assets/robot_7.png';
import RfHeart from '../../assets/rf_heart_icon.svg';

const Header = () => {
  return (
    <>
        <div className="rf__container-icon section__padding"><img src={ RfHeart } alt="robot feelings icon"/></div>
        <div className="rf__hero-grid">
            <div className="item rf__robot-1 flicker-in-4"><img src={ RobotOne } alt="Robot NFT"/></div>
            <div className="item rf__robot-2 flicker-in-3"><img src={ RobotTwo } alt="Robot NFT"/></div>
            <div className="item rf__robot-3 flicker-in-2"><img src={ RobotThree } alt="Robot NFT"/></div>  
            <div className="item rf__robot-4 flicker-in-1"><img src={ RobotFour } alt="Robot NFT"/></div>
            <div className="item rf__robot-5 flicker-in-2"><img src={ RobotFive } alt="Robot NFT"/></div>
            <div className="item rf__robot-6 flicker-in-3"><img src={ RobotSix} alt="Robot NFT"/></div>
            <div className="item rf__robot-7 flicker-in-4"><img src={ RobotSeven } alt="Robot NFT"/></div>
        </div>
    </>
  );
}

export default Header
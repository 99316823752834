import React from 'react';
import './footer.css';
import {Socials} from '../../components';
import FooterLogo from '../../assets/primary_footer.svg'

const Menu = () => (
    <>
        <ul>
            <li><a href="#artwork">THE ROBOTS</a></li>
            <li><a href="#feelings">THE FEELINGS</a></li>
            <li><a href="#team">THE TEAM</a></li>
            {/* <li><a href="#map">THE MAP</a></li> */}
        </ul>
    </>
)

const Footer = () => {
    return (
        <>
            <div className='rf__footer-container section__padding'>
                <div className='rf__footer-container_logo'>
                    <img src={FooterLogo}
                        alt='Robot Feelings logo'/>
                </div>
                <div className='rf__footer-links_container'>
                    <Menu/>
                </div>
                <div className='rf__footer-social'>
                    <Socials/>
                </div>
                <div className='rf__footer-copyright'>
                   ©2022 Robot Feelings, All Rights Reserved. 
                   <div>
                       <a href="https://raritysniper.com/nft-drops-calendar" target="_blank">NFT Drops</a>
                   </div>
                </div>
                
            </div>
        </>
    );
}

export default Footer

import React from 'react';
import './brand.css';
import RobotLine from '../../assets/handdrawnbot.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css/bundle';
import {Slider} from '../../components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// https://swiperjs.com/react
// const Slider = () => {
//     return (
//         <>
//           <div className='rf__swiper-container'>
//               <Swiper 
//                   grabCursor={true}
//                   slidesPerView={'auto'}
//                   freeMode={true}
//                   loop={true}
//                   watchOverflow={true}
//                   centeredSlides={true}
//                   autoplay={{
//                     disableOnInteraction: true,
//                   }}
//                   spaceBetween={0}
//                   modules={[Autoplay]}
//                   onSlideChange={
//                       () => console.log('slide change')
//                   }
//                   onSwiper={
//                       (swiper) => console.log(swiper)}>
//                   <SwiperSlide ><img src={Brand9}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand1}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand2}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand3}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand4}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand5}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand6}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand7}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>
//                   <SwiperSlide ><img src={Brand8}
//                           alt="Robot Feelinbgs Branding and History"/></SwiperSlide>       
//               </Swiper>
//           </div>
//         </>
//     );
// }

const Brand = () => {
    return (
        <>
            <div className="rf__container-brand section__padding">
                <div className="rf__container-brand_row">
                    <div data-aos="fade-up" className="rf__container-brand_column column__padding">
                        <h1>A BRAND IN THE MAKING</h1>
                        <p>Robot Feelings is more than an NFT collection.</p><br></br>
                        <p>It’s a dream to be something bigger than that.</p><br></br>
                        <p>It’s a brand that brings value to our community. A plan to build for the long term. An alternate universe. An animated series. A comic book. It’s whatever we want it to be.</p><br></br>
                        <p>We hope you’ll follow along our journey of bringing Robot Feelings to life.</p>
                    </div>
                    <div data-aos="fade-up" className="rf__container-brand_column">
                        <img src={RobotLine}
                            alt="Robot Sketch"/>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up">
                <Slider/>
            </div>
        </>
    );
}

export default Brand

import React from 'react';
import './feelings.css';
import Mellow from '../../assets/mellow.png';
import MellowH from '../../assets/mellowhov.png';
import Happy from '../../assets/happy.png';
import Excited from '../../assets/excited.png';
import Bored from '../../assets/bored.png';
import Sad from '../../assets/sad.png';
import Annoyed from '../../assets/annoyed.png';
import Angry from '../../assets/angry.png';
import Shutdown from '../../assets/shutdown.png';
import HappyOne from '../../assets/happy1.png';
import ExcitedOne from '../../assets/excited1.png';
import BoredOne from '../../assets/bored1.png';
import SadOne from '../../assets/sad1.png';
import AnnoyedOne from '../../assets/annoyed1.png';
import AngryOne from '../../assets/mad1.png';
import ShutOne from '../../assets/shutdown1.png';

const Feelings = () => {
    return (
        <>
            <div id="feelings" className='rf__container-feelings section__padding_large'>
                <div data-aos="fade-up" className="rf__container-feelings_text">
                    <h1>THE FEELINGS</h1>
                    <p>The Gen-1 Robots were invented by H.A.P.I. Labs many years ago.<br></br>
                        But one day, all 10101 Robots developed an unexpected glitch.<br></br>
                        <br></br>They began to feel something.</p>
                    <br></br>

                    <p>H.A.P.I. Labs didn’t create them to think or feel. So when they discovered this glitch, they set out to reset each and every one of them. But the Robots didn’t want to go back to being emotionless. So they escaped. To find their true purpose. To discover their true feelings.</p>
                    <br></br>

                    <p>On mint day, each Robot will finally get to express themselves.</p>

                    <p>I wonder what happens when you collect all eight feelings? &#x1F914;</p>

                    <br></br>
                </div>
                <div data-aos="fade-up" className="rf__feelings-row section__padding">
                    <div className="item rf__mellow">
                        <h2>MELLOW</h2>
                        <img src={Mellow}
                            onMouseOver={
                                e => (e.currentTarget.src = MellowH)
                            }
                            alt="mellow robot"/>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="50" className="item rf__happy">
                        <h2>HAPPY</h2>
                        <img src={Happy}
                            onMouseOver={
                                e => (e.currentTarget.src = HappyOne)
                            }
                            alt="happy robot"/>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="100" className="item rf__excited">
                        <h2>EXCITED</h2>
                        <img src={Excited}
                            onMouseOver={
                                e => (e.currentTarget.src = ExcitedOne)
                            }
                            alt="exxcited robot"/></div>
                    <div data-aos="fade-up" className="item rf__bored">
                        <h2>BORED</h2>
                        <img src={Bored}
                            onMouseOver={
                                e => (e.currentTarget.src = BoredOne)
                            }
                            alt="bored robot"/></div>
                    <div data-aos="fade-up" data-aos-delay="150" className="item rf__sad">
                        <h2>SAD</h2>
                        <img src={Sad}
                            onMouseOver={
                                e => (e.currentTarget.src = SadOne)
                            }
                            alt="sad robot"/></div>
                    <div data-aos="fade-up" data-aos-delay="200" className="item rf__annoyed">
                        <h2>ANNOYED</h2>
                        <img src={Annoyed}
                            onMouseOver={
                                e => (e.currentTarget.src = AnnoyedOne)
                            }
                            alt="annoyed"/></div>
                    <div data-aos="fade-up" data-aos-delay="250" className="item rf__angry">
                        <h2>ANGRY</h2>
                        <img src={Angry}
                            onMouseOver={
                                e => (e.currentTarget.src = AngryOne)
                            }
                            alt="angry robot"/></div>
                    <div data-aos="fade-up" data-aos-delay="300" className="item rf__shutdown">
                        <h2>SHUTDOWN</h2>
                        <img src={Shutdown}
                            onMouseOver={
                                e => (e.currentTarget.src = ShutOne)
                            }
                            alt="shutdown"/></div>
                </div>
            </div>

        </>
    );
}

export default Feelings

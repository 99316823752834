import React from 'react';
import './socials.css';
import logo from '../../assets/primary_rf_logo.svg';
import Twitter from '../../assets/fa-twitter.svg';
import Discord from '../../assets/Discord.svg';
import OpenSea from '../../assets/OpenSea.svg';

const Socials = () => {
  return (
    <ul>
        <li><a href="https://twitter.com/robotfeels" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a></li>
        {/* <li><a href="#"><img src={Discord} alt="discord"/></a></li> */}
        {/* <li><a href="#"><img src={OpenSea} alt="opensea"/></a></li> */}
    </ul>
  )
}

export default Socials
import React from 'react';
import './roadmap.css';
import '../../assets/rmbg.svg';

window.onscroll = function () {
  // Get a reference to the <path>
  var path = document.querySelector('#line1');
  
  // Get length of path... ~577px in this case
  var pathLength = path.getTotalLength();
  
  
  // Make very long dashes (the length of the path itself)
  path.style.strokeDasharray = pathLength + ' ' + pathLength;
  
  // Offset the dashes so the it appears hidden entirely
  path.style.strokeDashoffset = pathLength;
  
  // Jake Archibald says so
  // https://jakearchibald.com/2013/animated-line-drawing-svg/
  path.getBoundingClientRect();
  
  // When the page scrolls...
  window.addEventListener("scroll", function(e) {
   
    // What % down is it? 
    // https://stackoverflow.com/questions/2387136/cross-browser-method-to-determine-vertical-scroll-percentage-in-javascript/2387222#2387222
    // Had to try three or four differnet methods here. Kind of a cross-browser nightmare.
    var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      
    // Length to offset the dashes
    var drawLength = pathLength * scrollPercentage;
    
    // Draw in reverse
    path.style.strokeDashoffset = pathLength - drawLength;
      
    // When complete, remove the dash array, otherwise shape isn't quite sharp
   // Accounts for fuzzy math
    // if (scrollPercentage >= 0.99) {
    //   path.style.strokeDasharray = "none";
      
    // } else {
    //   path.style.strokeDasharray = pathLength + ' ' + pathLength;
    // }
    
  });
  }


const Roadmap = () => {
    return (
      <>
        <div className="rf__container-map_text">
            <h1>THE SCHEMATIC</h1>
        </div>
        <div id="map" className="wrapper section__padding">
            <div className="center-line">
                {/* <svg id="mysvg" className="mysvg" width="52" height="1252" viewBox="0 0 52 1252" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="line1" d="M26 52.5C26.057 52.5 26.1698 52.5346 26.2911 52.7167C26.4102 52.8953 26.5001 53.1707 26.5001 53.5L26.5 1198.5C26.5 1198.83 26.4101 1199.1 26.2911 1199.28C26.1697 1199.47 26.0569 1199.5 26 1199.5C25.943 1199.5 25.8303 1199.47 25.7089 1199.28C25.5898 1199.1 25.4999 1198.83 25.4999 1198.5L25.5 53.5C25.5 53.1707 25.5899 52.8953 25.7089 52.7167C25.8303 52.5346 25.9431 52.5 26 52.5Z" stroke="#F3F4F5" strokeWidth="3" strokeLinecap="square"/> */}

                    {/* <svg id="mysvg" width="52" height="1252" viewBox="0 0 52 1252" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="line1" fill-rule="evenodd" clip-rule="evenodd" d="M26 52C26.5523 52 27.0001 52.6716 27.0001 53.5L27 1198.5C27 1199.33 26.5523 1200 26 1200C25.4477 1200 24.9999 1199.33 24.9999 1198.5L25 53.5C25 52.6716 25.4477 52 26 52Z" fill="white" strokeWidth="3" strokeLinecap="square"/> */}

<svg id="mysvg" width="52" height="1012" viewBox="0 0 52 1012" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="line1" d="M27.5 293C27.5 292.172 26.8284 291.5 26 291.5C25.1716 291.5 24.5 292.172 24.5 293L27.5 293ZM24.5 479C24.5 479.828 25.1716 480.5 26 480.5C26.8284 480.5 27.5 479.828 27.5 479L24.5 479ZM24.5 293L24.5 479L27.5 479L27.5 293L24.5 293Z" fill="#F3F4F5"/>
<path d="M27.5 773C27.5 772.172 26.8284 771.5 26 771.5C25.1716 771.5 24.5 772.172 24.5 773L27.5 773ZM24.5 959C24.5 959.828 25.1716 960.5 26 960.5C26.8284 960.5 27.5 959.828 27.5 959L24.5 959ZM24.5 773L24.5 959L27.5 959L27.5 773L24.5 773Z" fill="#F3F4F5"/>
<path d="M27.5 53C27.5 52.1716 26.8284 51.5 26 51.5C25.1716 51.5 24.5 52.1716 24.5 53L27.5 53ZM24.5 239C24.5 239.828 25.1716 240.5 26 240.5C26.8284 240.5 27.5 239.828 27.5 239L24.5 239ZM24.5 53L24.5 239L27.5 239L27.5 53L24.5 53Z" fill="#F3F4F5"/>
<path d="M27.5 533C27.5 532.172 26.8284 531.5 26 531.5C25.1716 531.5 24.5 532.172 24.5 533L27.5 533ZM24.5 719C24.5 719.828 25.1716 720.5 26 720.5C26.8284 720.5 27.5 719.828 27.5 719L24.5 719ZM24.5 533L24.5 719L27.5 719L27.5 533L24.5 533Z" fill="#F3F4F5"/>
<g filter="url(#filter0_d_263_2)">
<circle cx="26" cy="26" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter1_d_263_2)">
<circle cx="26" cy="26" r="9" fill="#F3F4F5"/>
</g>
                    <g filter="url(#filter0_d_246_2)">
                        <circle cx="26" cy="26" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter1_d_246_2)">
                        <circle cx="26" cy="26" r="9" fill="#F3F4F5"/>
                    </g>
                    <g filter="url(#filter2_d_246_2)">
                        <circle cx="26" cy="266" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter3_d_246_2)">
                        <circle cx="26" cy="266" r="9" fill="#F3F4F5"/>
                    </g>
                    <g filter="url(#filter4_d_246_2)">
                        <circle cx="26" cy="506" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter5_d_246_2)">
                        <circle cx="26" cy="506" r="9" fill="#F3F4F5"/>
                    </g>
                    <g filter="url(#filter6_d_246_2)">
                        <circle cx="26" cy="746" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter7_d_246_2)">
                        <circle cx="26" cy="746" r="9" fill="#F3F4F5"/>
                    </g>
                    <g filter="url(#filter8_d_246_2)">
                        <circle cx="26" cy="986" r="16.5" stroke="#F3F4F5" strokeWidth="3" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter9_d_246_2)">
                        <circle cx="26" cy="986" r="9" fill="#F3F4F5"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_246_2" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter1_d_246_2" x="9" y="9" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter2_d_246_2" x="0" y="240" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter3_d_246_2" x="9" y="249" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter4_d_246_2" x="0" y="480" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter5_d_246_2" x="9" y="489" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter6_d_246_2" x="0" y="720" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter7_d_246_2" x="9" y="729" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter8_d_246_2" x="0" y="960" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter9_d_246_2" x="9" y="969" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter10_d_246_2" x="0" y="1200" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                        <filter id="filter11_d_246_2" x="9" y="1209" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_246_2"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="3.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_2"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_2" result="shape"/>
                        </filter>
                    </defs>
                </svg>


            </div>
            <div className="row row-1" data-aos="fade-up-right">
                <section>
               <h2>Robot Feelings Community</h2>
                        <ul className='rf__map-list'>
                            <li>IRL Events</li>
                            <li>Bot Ambassadors</li>
                            <li>Metaverse Initiatives</li>
                            <li>Community Voting</li> 
                        </ul>
                </section>
            </div>
            <div className="row row-2" data-aos="fade-up-left">
                <section>
                <h2>Film3 + Other Media</h2>
                    <p>You want to be early because we have really exciting things in store for this group </p>
                        <ul className='rf__map-list'>
                            <li>Producer Credit in All Robot Feelings Productions</li>
                            <li>Comic and Coloring Books </li>
                            <li>Animated Series and Film </li>
                            <li>Access to Future Mints </li> 
                        </ul>
                </section>
            </div>
            <div className="row row-1" data-aos="fade-up-right">
            <section>
            <h2>Merch (The Gear Shop)</h2>
                        <ul className='rf__map-list'>
                            <li>Free T Shirts and Stickers for our early supporters and <a href="https://mixtapesvol1.com/" target="_blank"></a> holders</li>
                            <li>Exclusive Future Merch</li>
                        </ul>
                        </section>
            </div>
            <div className="row row-2" data-aos="fade-up-left">
                <section>
                <h2>Partnerships</h2>
                    <p>Collaborations + Partnerships via Future Events, Giveaways, and More</p>
                </section>
            </div>
            {/* <div className="row row-1" data-aos="fade-up-right">
                <section>
                    <p>Lorem ipsum dolor sit ameters consectetur adipisicing elit. Sed qui veroes praesentium maiores, sint eos vero sapiente voluptas debitis dicta dolore.</p>
                </section>
            </div>
            <div className="row row-2" data-aos="fade-up-left">
                <section>
                    <p>Lorem ipsum dolor sit ameters consectetur adipisicing elit. Sed qui veroes praesentium maiores, sint eos vero sapiente voluptas debitis dicta dolore.</p>
                </section>
            </div> */}
          
        </div>
        </>
    );
}

export default Roadmap

import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/primary_rf_logo.svg';
import Twitter from '../../assets/fa-twitter.svg';
import IG from '../../assets/Instagram.svg';
import Discord from '../../assets/Discord.svg';
import OpenSea from '../../assets/OpenSea.svg';

const Menu = () => (
    <>
    <ul>
    <li><a href="#artwork">THE ROBOTS</a></li> 
    <li><a href="#feelings">THE FEELINGS</a></li> 
    <li><a href="#team">THE TEAM</a></li> 
    <li><a href="#map">THE SCHEMATIC</a></li> 
    <li><a href="https://feels.robotfeelings.io/" target="_blank">FEELS GENERATOR</a></li> 
    </ul>
    </>
)

const Social = () => (
    <>
    <ul>
        <li><a href="https://twitter.com/robotfeels" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a></li>
        <li><a href="https://instagram.com/robotfeelings" target="_blank" rel="noreferrer"><img src={IG} alt="Instagram"/></a></li>
        <li><a href="https://discord.gg/fqmEWKFGVD" target="_blank"><img src={Discord} alt="discord"/></a></li>
        <li><a href="https://opensea.io/collection/robot-feelings-official" target="_blank"><img src={OpenSea} alt="opensea"/></a></li>
    </ul>
    </> 
)

const Navbar = () => {
const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='rf__navbar'>
                    <div className='rf__navbar-links_logo'>
                <img src={logo} alt="logo"/>
            </div>
        <div className='rf__navbar-links'>

            <div className='rf__navbar-links_container'>
                <Menu />
            </div>
        </div>
        <div className='rf__navbar-social'>
            <Social />
        </div>
        <div className='rf__navbar-menu'>
          {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />}
          {toggleMenu && (
             <div className="rf__navbar-menu_container scale-up-center">
             <div className="rf__navbar-menu_container-links">
             <Menu />
             </div>
           </div>
           )}
      </div>
    </div>
  );
}

export default Navbar
import React, { useRef } from 'react';
import './App.css';
import { Artwork, Creator, Faq, Feelings, Footer, Brand, Header, Newsletter, Partners, Roadmap, Team } from './containers';
import { Navbar } from './components';
import ScrollToTop from "react-scroll-to-top";
import {Helmet} from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const App = () => {
  return (
    <>
     <div className='App'>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Robot Feelings</title>
                <link rel="canonical" href="https://robotfeelings.io" />
                <meta name="description" content="Robot Feelings is a 10101 generative NFT collection living on the Ethereum blockchain" />
            </Helmet>
     <ScrollToTop />
      <div className='background__bg'>
          <Navbar />
          <Header />
      </div>
      <Artwork />
      <Feelings />
      <Brand />
      <Creator />
      <Team />
      <Partners />
      <Roadmap />
      <Faq />
      <Newsletter /> 
      <Footer />
    </div>
    </>
  )
}

export default App;

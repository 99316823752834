export const faq = [
    {
        question: "What is Robot Feelings",
        answer: "A collection of 10101 Robot NFT's that will live on the Ethereum blockchain",
    },
    {
        question: "Wen Mint?",
        answer: "12/16/22",
    },
    {
        question: "What is the mint price?",
        answer: ".04 Ethereum",
    },
]; 